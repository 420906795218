.LDBP_Footer {
    width: 100%;
    .Ad {
        width: 100%;
        height: 270px;
        background: url('../res/footer_img_bg.png') repeat-x center;
        .Container {
            margin: 0 auto;
            width:1180px;
            text-align: center;
            font-size: 34px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            p {
                padding-top: 65px;
                // -webkit-box-reflect: below -15px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(188,188,188, 0.1)));
            }
            .StartButton{
                width: 135px;
                height: 44px;
                margin-top: 45px;
                background-image: linear-gradient(90deg, 
                    #b7945d 0%, 
                    rgba(160, 136, 98, 0.24) 50%, 
                    #b7945d 66%, 
                    #b7945d 100%), 
                linear-gradient(
                    #b7945d, 
                    #b7945d);
                background-blend-mode: normal, 
                    normal;
                border-radius: 2px;
                color:white;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 1px;
                -webkit-box-reflect: below 0 -webkit-linear-gradient(transparent,transparent 30%,rgba(188,188,188,.2));
            }
        } 
    }

    .Footer{
        width: 100%;
        height: 312px;
        background-color: #404040;
        .Container {
            margin: 0 auto;
            padding-top: 91px;
            width:879px;
            .Info{
                float:left;
                p {
                    color: white;
                    text-align: center;
                    width: max-content;
                }
                .AddInfo {
                    float:left;
                    .CoLtd {
                        font-size: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        letter-spacing: 0px;
                        margin-bottom: 30px;
                    }
                    .Address {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        letter-spacing: 0px;
                        color: #ffffff;
                        opacity: 0.8;
                        margin-bottom: 20px;
                    }
                }
            }
            .footLogo{
                float:right;
                text-align: center;
            }
        }
        
    }
    .Ext {
        width: 100%;
        height: 250px;
        background-color: #b7945d;
        .icpClass {
            margin: 0 auto;
            padding-top: 40px;
            text-align: center;
            width: max-content;
            height: 15px;
            font-family: MicrosoftYaHei;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 0.6;
        };
        .beian {
            margin-top: 50px;
            width: 220px;
        }
    }
}
