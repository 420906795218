.ComponentStyle{
    width: 100%;
    min-width: 1180px;
    .Banner{
        margin-top: 30px;
        .Container {
            .MardDown {
                float: left;
                padding-left: 52px;
                color: #aeb4c3;
                margin: 0 auto;
                
                h4 {
                    font-size: 28px;
                }
                p {
                    margin-bottom:48px;
                    font-size: 16px;
                }
            }
            .LoginContainer {
                display: none;
                float:right;
                margin-right: 0px;
                margin-top: 10px;
                width:350px;
                height:286px;
                background-color: #293146;
	            border-radius: 2px;
            }
        }
    }
    
    .Platfrom, .HomeCase, .Flow {
        margin-top:65px;
    }

    .Product, .Guide {
        margin-top:70px;
        .TitleContainer {
            padding-top:45px;
        }
    }

    .Platfrom, .Product, .HomeCase, .Guide, .Flow{
        .Container {
                margin: 0 auto;
                width: 1180px;
                background-color: transparent;
        }
    }

    .Platfrom{
        background-color: white;
        .Container {
            height:298px;
        }
    }

    .Product{
        background-color: #fafafa;
        height: 420px;
    }

    .HomeCase{
        height: 433px;
        background-color: white;
        .Container {
            height:318px;
            position: relative;
        }
    }

    .Guide{
        background-color: #fafafa;
        height: 598px;
        .Container {
            height:460px;
        }
    }

    .Flow{
        background-color: white;
        .Container {
            margin-bottom: 20px
        }
    }

    .TitleContainer {
        text-align: center;
        color: #b7945d;
    }

    .FlowButton{
        text-align: center;
        margin-bottom: 50px;
    }

    .RightNowButton {
        border: 2px solid #666666;
        width: 200px;
        height: 44px;
        margin-top: 35px;
        color:#666666;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
    }
}
