.ComponentStyle{
    width: 100%;
    min-width: 1180px;
    height:auto;
    .ProductBanner {
        display: block;
    }
    .Banner{
        width: 100%;
        .MotorContainer, .BuilderContainer {
            width: 1180px;
            height:100%;
            margin: 0 auto;
            .Icon {
                padding-top:138px;
                float: right;
                width:433px;
                height: 250px;
            }
            .Info {
                float: left;
                padding-top:155px;
                padding-left: 52px;
                margin: 0 auto;
                
                h4 {
                    font-size: 32px;
                    font-weight: normal;
                    font-stretch: normal;
                    color: #aeb4c3;
                    letter-spacing: 1px;
                    margin-bottom: 40px;
                }
                p {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 1px;
                    color: #aeb4c3;
                }
            }
            .LinkButton {
                width: 130px;
                height: 44px;
                background-image: linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147);
                background-blend-mode: normal, normal;
                border-radius: 2px;
                text-align: center;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0px;
                color: #ffffff;
                margin-top: 343px;
                margin-left: -492px
            }
        }
    }

    .Charact, .Support, .TagHistory{
        margin-top:70px;
        .Container {
            margin: 0 auto;
            width: 1180px;
            background-color: transparent;
        }
    }

    .Charact{
        .HomeContainer {
            background-color: white;
            .Container {
                height:360px;
            }
        }
        .BackGround {
            width: 100%;
            height: 540px;
            // background-color: #fafafa;
            margin-top: 170px;
        }
    }

    .Support{
        background-color: rgb(245,245,245);
        padding-top: 40px;
        .HomeContainer {
          
            .Container {
                height:300px;
            }
        }
    }

    .TagHistory{
        margin-top:20px;
        // background-color: #fafafa;
        height:591px;
        .TitleContainer {
            padding-top:70px;
        }
        .Container {
            height:571px;
        }
    }
}
