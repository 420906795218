.ComponentStyle{
    width: 100%;
    min-width: 1180px;
    height:auto;
    .Banner{
        width: 100%;
        height: 570px;
        background: url('../res/banner.png') no-repeat center top;
        .Container {
            width: 1180px;
            height:auto;
            margin: 0 auto;
            .Info {
                float: left;
                padding-top:155px;
                padding-left: 52px;
                margin: 0 auto;
                
                h4 {
                    font-size: 32px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 1px;
                    color: #aeb4c3;
                    margin-bottom: 32px;
                }
                p {
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 1px;
                    color: #aeb4c3;
                    opacity: 0.8;
                }
            }
            .brief {
                
            }
        }
    }
    .TitleContainer {
        margin: 0 auto;
        width: 1180px;
        background-color: transparent;
        margin-bottom: 45px;
    }
}
