.ComponentStyle{
    //margin-bottom:100px;
    .CaseTitleContainer {
        margin-bottom: 0 !important;
    }
    .TitleContainer {
        width: 1180px;
    }
    .Case{
        margin-top:50px;
        .HomeContainer_1, .HomeContainer_2{
            height: 500px;
            width: 100%;
        }
        .HomeContainer_1 {
            background-color: white;
        }
        .HomeContainer_2 {
            background-color: #fafafa;
        }
        .Container {
            margin: 0 auto;
            width: 1180px;
            height: 560px;
            background-color: transparent;
            display:flex;
            .nums{
                width:40px;
                height:40px;
                text-align: center;
                line-height:40px;
                font-size: 18px;
                color: #fff;
                background-color: #b7945d
            }
        }
    }
}