.NavButton {
    width: max-content;
    height: 40px;
    margin-right:15px !important;
    span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        // color: #000000;
        opacity: 1;
    }
}
.logo_title{
    // color: #ffffff;
    opacity: 0.9;
}

.MenuButtonMotor {
    height: 32px;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 0.9;
    min-height: 0px !important;
    width: max-content !important;
    margin-left: 11px !important;
    top: 14px !important;
}

.MenuButtonBuilder {
    height: 32px;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 0.9;
    min-height: 0px !important;
    width: max-content !important;
    margin-left: 11px !important;
    top: 20px;
}

.NavButton:hover, .MenuButton:hover{
    // background: rgba(200, 200, 200, 0.2) !important;
    // border-bottom: 2px solid rgba(255,255,255,.05) !important;
    border-radius: 0 !important;
    color: #b7945d !important;
}
