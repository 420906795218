.ComponentStyle{
    //margin-bottom:100px;
    .CaseTitleContainer {
        margin-bottom: 0 !important;
    }
    .TitleContainer {
        width: 1180px;
    }
    .MotorPrice{
        margin-top:70px;
        .Container {
            margin: 0 auto;
            width: 1220px;
            height: 705px;
            background-color: transparent;
        }
    }
    .BuilderPrice{
        margin-top:70px;
        height: 442px;
        .TitleContainer{
            padding-top: 45px;
        }
        .Container {
            margin: 0 auto;
            width: 1180px;
            background-color: transparent;
        }
    }
    .Service{
        margin-top:70px;
    }
}